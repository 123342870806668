<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">

          <h2 class="brand-text text-primary ml-1">
            dashboardPRO
          </h2>
        </b-link>

        <b-card-title class="mb-1 text-center">
          Bem vindo!
        </b-card-title>

        <!-- form -->
        <validation-observer ref="loginValidation">
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <!-- email -->
            <b-form-group
              label="Email"
              label-for="login-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="login-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="login-email"
                  placeholder="usuario@email.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- forgot password -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <label for="login-password">Senha</label>
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="login-password"
                    v-model="password"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <div class="d-flex justify-content-end">
                <b-link :to="{name:'perdi-minha-senha'}">
                  <small>Esqueceu a sua Senha?</small>
                </b-link>
              </div>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Lembrar do login
              </b-form-checkbox>
            </b-form-group>

            <!-- submit buttons -->
            <b-button
              type="submit"
              variant="primary"
              block
              @click="openTerms"
            >
              Entrar
            </b-button>
          </b-form>
        </validation-observer>

      </b-card>
    </div>
    <b-modal ref="termos"
             v-model="showTerms"
             size="md"
             title="Termos e Condições"
             hide-header-close
             no-close-on-backdrop
             no-close-on-esc
             centered
             ok-title="Aceito os termos"
             cancel-title="Não Aceito"
             @ok="acceptTerms"
    >
      <h3>Declaração e Termo de Ciência da Politica de Privacidade</h3>
      <p>
        Ao utilizar o dashboardPRO, você declara que está ciente da
        <a href="https://hksistemas.inf.br/politica-de-privacidade/" target="_blank">
          Política de Privacidade
        </a> e ciente de sua responsabilidade
        ao acessar dados sensíveis de usuários e/ou dispositivos constantes no console Admin Google de sua organização.
      </p>
      <p>
        Inclusive ao utilizar a função ‘Monitorar’ para coletar e tratar dados pessoais por meio de captura de informações,
        ciente de que deve cumprir o que estabelece a LGPD (Lei Geral de Proteção de Dados).
      </p>
      <p />
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import moment from 'moment-timezone'
import {
  BButton,
  BCardTitle,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink,
} from 'bootstrap-vue'
import { email, required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/logo/fronteira-geologia-logo.png'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      showTerms: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    ...mapActions('auth', ['me']),
    async login(saveTerm) {
      const isValid = await this.$refs.loginValidation.validate()
      if (!isValid) {
        return
      }
      const authUser = await this.$http.post(
        'auth/login',
        { email: this.userEmail, password: this.password, term: saveTerm },
      )
      if (authUser.error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Usuário ou senha inválidos',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
        const key = `terms_accepted${this.userEmail}`
        localStorage.removeItem(key)
        return
      }
      sessionStorage.setItem('accessToken', authUser.access_token)
      const me = await this.me()
      sessionStorage.setItem('userData', JSON.stringify(me))
      this.$router.push({ name: 'dashboard' })

    },
    async openTerms() {
      const isValid = await this.$refs.loginValidation.validate()
      if (!isValid) {
        return false
      }
      const key = `terms_accepted${this.userEmail}`
      const termsAccepted = localStorage.getItem(key) || false
      if (!termsAccepted) {
        this.$refs.termos.show()
        return false
      }
      await this.login(false)
      return true
    },
    async acceptTerms(event) {
      event.preventDefault()
      const key = `terms_accepted${this.userEmail}`
      localStorage.setItem(key, moment().format('YYY-MM-DD H:i:s'))
      this.$refs.termos.hide()
      this.showTerms = false
      await this.login(true)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
